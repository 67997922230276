import Logo from '~/assets/logo.svg';
export default function ApplicationMark() {
  return (
    <div className="font-mono px-4 md:space-y-5 flex flex-col justify-center items-center py-4">
      <img src={Logo} alt="DViO One logo" className="h-24 " />
      <div className="hidden md:flex flex-col items-center   ">
        <div className="text-lg md:text-2xl text-center font-semibold">
          Get Data Clarity, Optimize Faster,
        </div>
        <div className="text-lg md:text-2xl text-center font-semibold">
          and Predict the Future.
        </div>
      </div>
    </div>
  );
}
